import React from 'react';
import './Introduction.css';

function Introduction() {
    return (
        <div className='introduction'>
            <p>I'm Rami and I'm a Computer Science student at the 
                University of Waterloo in Ontario, Canada.
            </p>
        </div>
    )
}

export default Introduction;
